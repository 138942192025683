.Skew {
    float: left;
    display: block;
    position: relative;
    min-width: 150px;
    min-height: 150px;
    margin: 10px;
    z-index: 3;
}

.SkewBorder {
    position: absolute;
    float: left;
    width: 100%;
    height: 100%;
    background: #fff;
}

.SkewBorder:before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    transform: skew(2deg, 2deg);
    z-index: -1;
}