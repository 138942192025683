:root {
    --button-size-lg: 50px;
    --button-size-sm: 40px;
}

.Backdrop {
    position: fixed;
    top: -100%;
    right: 0;
    background-color: #000000A0;
    -moz-backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    filter: blur(4px);
    width: 100%;
    height: 100vh;
    transition: all 0.6s;
    pointer-events: fill;
    z-index: 149;
}

.Backdrop.Open {
    top: 0;
}

.TopNav {
    position: fixed;
    top: 0;
    margin: 0;
    padding: 0;
    padding-right: 12px;
    height: 100px;
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    background: transparent;
    transition: all 0.3s ease-in-out;
    z-index: 150;
}

.TopNav.Open {
    height: 100vh;
}

.NavContainer {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    background: transparent;
}

.TopNav .NavContainer .Showable {
    min-height: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.TopNav .NavContainer .Collapsable {
    min-height: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    overflow: hidden;
}

.TopNav.Open .NavContainer .Collapsable {
    min-height: none;
    height: 100%;
}


.TopNav .NavContainer .Collapsable .Nav {
    padding: 0 8px;
    text-align: left;
    border-radius: 0;
    width: 100%;
    border-bottom: 1px solid var(--text-color);
}

.TopNav .Nav {
    font-size: 24px;
    font-family: "Russo One", sans-serif;
    font-weight: bold;
    color: var(--text-color);
    background-color: transparent;
    height: var(--button-size-lg);
    min-width: var(--button-size-lg);
    line-height: var(--button-size-lg);
    padding: 0;
    text-align: center;
    margin: 0;
    border-radius: 4px;
    cursor: pointer;
    outline-style: none;
    overflow: hidden;
}

.TopNav .Nav span {
    display: inline;
    font-size: 16px;
    font-weight: normal;
}

.TopNav .Nav:hover {
    background-color: var(--text-color);
    color: var(--background-color);
}

.TopNav .Nav.Hamburger {
    display: block;
}


@media (min-width: 600px) {
    .Backdrop {
        display: none;
    }

    .TopNav.Open {
        height: 100px;
    }

    .TopNav .NavContainer {
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-end;
    }

    .TopNav .NavContainer .Showable {
        height: 100px;
    }

    .TopNav .NavContainer .Collapsable {
        width: auto;
        min-height: 0;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }

    .TopNav .NavContainer .Collapsable .Nav {
        padding: 0;
        text-align: center;
        border-radius: 4px;
        width: auto;
        border-bottom: 0;
    }

    .TopNav .NavContainer .Collapsable .Nav span {
        display: none;
    }

    .TopNav .Nav.Hamburger {
        display: none;
    }

}







.Dropdown {
    float: left;
}

.Dropdown.Show {
    background-color: #00000080;
    box-shadow: var(--box-shadow);
    border-radius: 4px 4px 0 0;
}

.DropdownContent {
    display: none;
    position: absolute;
    width: var(--button-size-lg);
    background-color: #00000080;
    box-shadow: var(--box-shadow);
    border-radius: 0 0 4px 4px;
    -moz-backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    overflow: hidden;
    z-index: 1;
}

.DropdownContent.Show {
    display: block;
}