.Header {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 320px;
    padding: 0 16px;
    font-family: sans-serif;
    font-weight: 400;
}

.Heading {
    text-align: left;
    font-size: 14px;
}

.Heading big {
    font-family: "Russo One", sans-serif;
}

.Heading::first-letter {
    font-size: 150%;
}

.Heading span {
    color: var(--primary);
    transition: all 0.3s;
}