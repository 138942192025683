.RightIndicator {
    position: absolute;
    right: 100px;
    bottom: 140px;
    height: 40px;
    width: 235px;
    overflow: hidden;
}

.RightIndicator i {
    position: absolute;
    line-height: 40px;
    font-size: 32px;
    left: 175px;
    letter-spacing: 10px;
    z-index: 1;
    animation: swipe 2.5s ease-in-out infinite
}

.RightIndicator span {
    position: absolute;
    line-height: 40px;
    font-size: 32px;
    width: 175px;
    white-space: nowrap;
    overflow: hidden;
    left: 0;
    animation: anit-swipe 2.5s ease-in-out infinite;
}


@keyframes swipe {

    0%,
    100% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(-175px);
    }

}

@keyframes anit-swipe {

    0%,
    100% {
        width: 175px;
    }

    50% {
        width: 0;
    }
}