.Snackbar {
	position: fixed;
	top: 55px;
	right: 5px;
	z-index: 999;
	display: block;
	padding: 8px 16px;
	width: 250px;
	min-height: 30px;
	border-radius: 4px;
	box-shadow: var(--box-shadow);
	text-align: center;
	-webkit-animation: slidein 0.5s,
		slideout 0.5s 4.5s;
	animation: slidein 0.5s,
		slideout 0.5s 4.5s;
}

.Snackbar.Primary {
	background-color: var(--primary);
}

.Snackbar.Success {
	background-color: var(--success);
}

.Snackbar.Warning {
	background-color: var(--warning);
}

.Snackbar.Danger {
	background-color: var(--danger);
}

@-webkit-keyframes slidein {
	from {
		right: -100%;
		opacity: 0;
	}

	to {
		right: 10px;
		opacity: 1;
	}
}

@keyframes slidein {
	from {
		right: -100%;
		opacity: 0;
	}

	to {
		right: 10px;
		opacity: 1;
	}
}

@-webkit-keyframes slideout {
	from {
		right: 10px;
		opacity: 1;
	}

	to {
		right: -100%;
		opacity: 0;
	}
}

@keyframes slideout {
	from {
		right: 10px;
		opacity: 1;
	}

	to {
		right: -100%;
		opacity: 0;
	}
}