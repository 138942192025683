.HR {
	float: left;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-flow: row nowrap;
	width: calc(100% - 10px);
	min-height: 20px;
	padding: 0;
	text-align: left;
	margin: 20px 5px 0 5px;
	overflow: hidden;
	z-index: 20;
}

.HR .HRStart,
.HR .HREnd {
	height: 0;
	max-height: 0;
	margin: 0;
	padding: 0;
	border: 0;
	opacity: 0.6;
	z-index: 25;
}

.HR .HRStart {
	width: 30px;
}

.HR .HRSegment {
	white-space: nowrap;
	margin: 0 5px;
	z-index: 25;
}

.HR .HREnd {
	flex: 1
}