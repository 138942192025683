.Footer {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 0 8px;
    width: 100%;
    height: 75px;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    /* background-color: #000000A0; */
    pointer-events: none;
    z-index: 200;
}

.FooterText {
    text-align: right;
    font-size: 12px;
    left: 0;
    bottom: 0;
    padding: 2px 6px;
    margin: 0;
}

.Love {
    color: var(--danger);
}

.Money {
    color: var(--success);
}

.FooterNav {
    margin: 0;
    padding: 2px 4px;
    background-color: transparent;
    color: var(--text-color);
    font-size: 12px;
    border-radius: 3px;
    cursor: pointer;
    pointer-events: all;
}


.FooterNav:hover {
    background-color: var(--text-color);
    color: var(--background-color);
}

@media (min-width: 900px) {
    .Footer {
        height: 50px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background-color: transparent;
    }
}