:root {
	--primary: #09A2E5;
	--primary-dark: #4267B2;
	--secondary: #FDCA40;
	--secondary-dark: #c08517;
	--text-color: #ffffff;
	--text-shadow: 2px 2px 4px #ffffff;
	--success: #38c172;
	--info: #6cb2eb;
	--warning: #ffc107;
	--danger: #e3342f;
	--light: #f8f9fa;
	--dark: #343a40;
	--box-shadow: 0 4px 8px 0 rgba(12, 10, 10, 0.2), 0 6px 20px 0 rgba(255, 255, 255, 0.19);
	--inset-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2) inset, 0 6px 20px 0 rgba(0, 0, 0, 0.19) inset;
	--background-color: #000000;
	--font-size: 14px;
	--font-family-ruso-one: 'Russo One', sans-serif;
}