.Services p {
    padding: 4px 18px;
    text-indent: 30px;
    text-align: justify;
    z-index: 5;
}

.Services .Heading {
    padding: 4px 16px;
    z-index: 5;
}

.Overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000000A0;
    z-index: 2;
}

.BackImage {
    position: absolute;
    object-fit: contain;
    filter: blur(2px);
    -webkit-filter: blur(2px);
    width: 100%;
    left: 0;
    bottom: -150px;
    z-index: 1;
    pointer-events: none;
}