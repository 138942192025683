.FashionContainer {
    float: left;
    width: 100%;
    height: 350px;
    margin: 0;
    padding: 12px;
}

.CurrentDate {
    position: relative;
    float: right;
    overflow: hidden;
    padding: 0;
    margin: 0;
    height: 150px;
    width: 320px;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-end;
    justify-content: flex-end;
    z-index: 15;
}

.CurrentDate .Secondary {
    flex: 1;
    padding: 16px 0;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
    align-items: flex-start;
}

.Date {
    padding: 8px 0;
    margin: 0;
    font-size: 128px;
    line-height: 108px;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: var(--text-color);
    font-family: 'Russo One', sans-serif;
}

.Month {
    font-size: 32px;
    line-height: 36px;
}

.Info {
    font-size: 18px;
    line-height: 18px;
    white-space: nowrap;
}


.Info.Open {
    color: var(--success);
}

.Info.AboutToClose,
.Info.AboutToOpen {
    color: var(--warning);
}

.Info.Closed {
    color: var(--danger);
}


.CircularDiv {
    position: absolute;
    top: 10px;
    right: 100px;
    border-radius: 50%;
    width: 300px;
    height: 300px;
    display: inline-block;
    background-color: transparent;
    border: 2px solid var(--text-color);
    border-top: 2px solid transparent;
    transform: rotate(45deg);
    z-index: 10;
}

.Indicator {
    font-size: 24px;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: var(--text-color);
    animation: indicator 1s ease-in-out infinite;
}

@keyframes indicator {

    0%,
    100% {
        transform: translate(-5px, 0)
    }

    50% {
        transform: translate(5px, 0)
    }
}

.CircularDiv .CircularMessage {
    position: absolute;
    bottom: 50%;
    left: 50%;
    width: 300px;
    height: 100px;
    padding: 8px;
    transform: rotate(-45deg) translate(-140px, -20%);
    border-radius: 0 0 70px 70px;
    font-size: 18px;
    color: var(--text-color);
    font-family: 'Russo One', sans-serif;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
}

.ContactAction {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.ContactAction i {
    color: var(--text-color);
    font-size: 32px;
    padding: 2px 4px;
}

.ContactAction i:hover {
    color: var(--success)
}


.ContactAction p {
    color: var(--text-color);
    font-size: 16px;
    margin: 2px 4px;
}