.Layout {
    float: left;
    position: relative;
    width: 100%;
    min-height: 100vh;
    z-index: 5;
    background-color: var(--background-color);
    overflow: hidden;
    transition: all 0.3s;

}

.Background {
    position: fixed;
    top: -10vh;
    left: -10vw;
    width: 120vw;
    height: 120vh;
    z-index: -1;
}

.Container {
    position: fixed;
    top: 100px;
    width: 100%;
    height: calc(100vh - 100px);
    scrollbar-width: none;
    scroll-behavior: smooth;
    padding: 0 0 75px 0;

    overflow-x: auto;
    overflow-y: hidden;
    transition: all 0.3s;
}

.FlexContainer {
    position: relative;
    float: left;
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: hidden;
    white-space: nowrap;
    transition: all 0.3s;
}

@media (min-width: 900px) {
    .Container {
        padding: 0 0 50px 0;
    }
}