.Brand {
    position: fixed;
    top: 0;
    float: left;
    text-align: left;
    height: 100px;
    padding: 16px 24px;
    font-family: 'Russo One', sans-serif;
    display: flex;
    flex-flow: column nowrap;
    z-index: 250;
}

.Brand .BrandName {
    font-size: 48px;
    line-height: 50px;
    z-index: 250;
}

.Brand .TagLine {
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 2px;
    z-index: 250;
}