.Table {
	float: left;
	margin: 20px 5px;
	width: calc(100% - 10px);
	border-collapse: collapse;
	/* box-shadow: var(--box-shadow); */
}

.Table tr:nth-child(even) {
	background-color: #dddddd;
}

.Table tr {
	display: table-row;
}

.Table .XS,
.Table .SM,
.Table .LG,
.Table .XL,
.Table .Free {}

.Table .XS {
	width: 50px;
	max-width: 50px;
}

.Table .SM {
	width: 75px;
	max-width: 75px;
}

.Table .LG {
	width: 100px;
	max-width: 100px;
}

.Table .XL {
	width: 150px;
	width: 150px;
}

.Table .Free {
	width: 150px;
	min-width: 150px;
}

.Table th,
.Table td {
	position: relative;
	margin: 0;
	padding: 4px 12px;
	/* width: 100%; */
	border: 1px solid #e1e1e1;
}

.Table .RightAlign {
	text-align: right;
}

.Table .CenterAlign {
	text-align: center;
}

.Table .LeftAlign {
	text-align: left;
}

.Table input,
.Table textarea {
	float: left;
	display: block;
	margin: 0;
	padding: 4px 2px;
	width: 100%;
}