.Backdrop {
	width: 100%;
	height: 100%;
	position: fixed;
	bottom: 0;
	right: 0;
	background-color: #00000080;
	-moz-backdrop-filter: blur(4px);
	-webkit-backdrop-filter: blur(4px);
	backdrop-filter: blur(4px);
	z-index: 250;
}

.Backdrop span {
	color: #fff;
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translate(-50%);
}