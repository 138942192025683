.SocialSharing {
    float: left;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.SocialIcon {
    font-size: 56px;
    background-color: transparent;
    color: var(--text-color);
    cursor: pointer;
    outline-style: none;
}

.SocialIcon:hover,
.SocialIcon:focus {
    outline-style: none;
}

.Facebook {
    color: #3b5998;
}

.Messenger {
    color: #00B2FF;
}

.Instagram {
    color: #3f729b
}

.Twitter {
    color: #00acee;
}

.WhatsApp {
    color: #128C7E;
}