.Section {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    height: 80%;
    border-radius: 4px;
    text-align: left;
    transform: translate(-50%, -50%);
    padding: 0;
    overflow: hidden;
}

@media (min-width: 600px) {
    .Section {
        height: 100%;
    }
}