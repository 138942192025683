.Button,
.ProcessingButton {
	position: relative;
	display: inline-block;
	border: none;
	color: var(--primary);
	background-color: transparent;
	cursor: pointer;
	font: inherit;
	padding: 8px 12px;
	margin: 4px;
	font-size: 14px;
	font-weight: bold;
	overflow: hidden;
	transition: 0.2s;
	outline-style: none;
}

.ProcessingButton:hover,
.ProcessingButton:focus,
.ProcessingButton:active {
	outline-style: none;
}

.Button:hover,
.Button:focus,
.Button:active {
	background-color: var(--primary);
	color: var(--background-color);
}

.Button:active {
	text-shadow: var(--text-shadow);
	box-shadow: var(--inset-shadow);
}



.Button.Danger {
	color: var(--danger);
}

.Button.Danger:hover,
.Button.Danger:focus,
.Button.Danger:active {
	background-color: var(--danger);
	color: var(--background-color);
}

.ProcessingButton.Warning {
	color: var(--warning);
}

.Button.Warning:hover,
.Button.Warning:focus,
.Button.Warning:active {
	background-color: var(--warning);
	color: var(--background-color);
}

.Button.Success {
	color: var(--success);
}

.Button.Success:hover,
.Button.Success:focus,
.Button.Success:active {
	background-color: var(--success);
	color: var(--background-color);
}

.ProcessingButton span {
	position: absolute;
	display: block;
}

.ProcessingButton span:nth-child(1) {
	top: 0;
	left: -100%;
	width: 100%;
	height: 2px;
	background: linear-gradient(90deg, transparent, var(--primary));
	animation: swipeRight;
	animation-duration: 1s;
	animation-delay: 0;
	animation-iteration-count: infinite;
}


.ProcessingButton span:nth-child(2) {
	top: -100%;
	right: 0;
	width: 2px;
	height: 100%;
	background: linear-gradient(180deg, transparent, var(--primary));
	animation: swipeBottom;
	animation-duration: 1s;
	animation-delay: 0.25s;
	animation-iteration-count: infinite;
}

.ProcessingButton span:nth-child(3) {
	bottom: 0;
	right: -100%;
	width: 100%;
	height: 2px;
	background: linear-gradient(270deg, transparent, var(--primary));
	animation: swipeLeft;
	animation-duration: 1s;
	animation-delay: 0.5s;
	animation-iteration-count: infinite;
}


.ProcessingButton span:nth-child(4) {
	bottom: -100%;
	left: 0;
	width: 2px;
	height: 100%;
	background: linear-gradient(0, transparent, var(--primary));
	animation: swipeTop;
	animation-duration: 1s;
	animation-delay: 0.75s;
	animation-iteration-count: infinite;
}

.ProcessingButton.Danger span:nth-child(1) {
	background: linear-gradient(90deg, transparent, var(--danger));
}

.ProcessingButton.Danger span:nth-child(2) {
	background: linear-gradient(180deg, transparent, var(--danger));
}

.ProcessingButton.Danger span:nth-child(3) {
	background: linear-gradient(270deg, transparent, var(--danger));
}

.ProcessingButton.Danger span:nth-child(4) {
	background: linear-gradient(180deg, transparent, var(--danger));
}


.ProcessingButton.Warning span:nth-child(1) {
	background: linear-gradient(90deg, transparent, var(--warning));
}

.ProcessingButton.Warning span:nth-child(2) {
	background: linear-gradient(180deg, transparent, var(--warning));
}

.ProcessingButton.Warning span:nth-child(3) {
	background: linear-gradient(270deg, transparent, var(--warning));
}

.ProcessingButton.Warning span:nth-child(4) {
	background: linear-gradient(180deg, transparent, var(--warning));
}

.ProcessingButton.Success span:nth-child(1) {
	background: linear-gradient(90deg, transparent, var(--success));
}

.ProcessingButton.Success span:nth-child(2) {
	background: linear-gradient(180deg, transparent, var(--success));
}

.ProcessingButton.Success span:nth-child(3) {
	background: linear-gradient(270deg, transparent, var(--success));
}

.ProcessingButton.Success span:nth-child(4) {
	background: linear-gradient(180deg, transparent, var(--success));
}


@keyframes swipeRight {
	from {
		left: -100%;
	}

	to {
		left: 100%;
	}
}

@keyframes swipeBottom {
	from {
		top: -100%;
	}

	to {
		top: 100%;
	}
}

@keyframes swipeLeft {
	from {
		right: -100%;
	}

	to {
		right: 100%;
	}
}

@keyframes swipeTop {
	from {
		bottom: -100%;
	}

	to {
		bottom: 100%;
	}
}