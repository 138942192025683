html {
  box-sizing: border-box;
  letter-spacing: 1.2px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

*,
*::before,
*::after {
  outline-style: none;
  position: relative;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  scrollbar-width: none;
  scroll-behavior: smooth;
  -webkit-user-select: none;
  -webkit-app-region: no-drag;
}

*:focus,
*:active {
  outline-style: none;
}

*::-webkit-scrollbar {
  width: 0;
  height: 0;
}

*::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: var(--primary);
}

body {
  margin: 0;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  min-height: 100vh;
  overflow: hidden;
  color: var(--text-color);
  background-color: var(--background-color);
  -webkit-overflow-scrolling: touch;
  font-family: 'PT Sans', sans-serif;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*Hiding Arrow in Number Feild*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

a,
a:hover {
  color: var(--primary);
  cursor: alias;
  text-decoration: none;
}