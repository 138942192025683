.Area {
    position: fixed;
    background: transparent;
    width: 100vw;
    height: 100vh;
    pointer-events: none;
    z-index: -1;
}

.Circles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.Circles li {
    position: absolute;
    display: block;
    list-style: none;
    font-size: 18px;
    opacity: 0.5;
    background: transparent;
    color: var(--text-color);
    animation: animate 25s linear infinite;
    bottom: -150px;
    object-fit: contain;
}


.Circles li:nth-child(1) {
    left: 25%;
    font-size: 56px;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}


.Circles li:nth-child(2) {
    left: 10%;
    font-size: 12px;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.Circles li:nth-child(3) {
    left: 70%;
    font-size: 12px;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.Circles li:nth-child(4) {
    left: 40%;
    font-size: 36px;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}

.Circles li:nth-child(5) {
    left: 65%;
    font-size: 12px;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.Circles li:nth-child(6) {
    left: 75%;
    font-size: 68px;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.Circles li:nth-child(7) {
    left: 35%;
    font-size: 108px;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}

.Circles li:nth-child(8) {
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.Circles li:nth-child(9) {
    left: 20%;
    font-size: 10px;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.Circles li:nth-child(10) {
    left: 65%;
    font-size: 112px;
    width: 150px;
    height: 150px;
    animation-delay: 5s;
    animation-duration: 11s;
}

.Circles li:nth-child(11) {
    left: 0%;
    font-size: 112px;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 10s;
}

.Circles li:nth-child(12) {
    left: 90%;
    font-size: 112px;
    width: 150px;
    height: 150px;
    animation-delay: 0;
    animation-duration: 18s;
}



@keyframes animate {

    0% {
        transform: translateY(0) rotate(0deg);
        opacity: 0.5;
    }

    100% {
        transform: translateY(-120vh) rotate(720deg);
        opacity: 0.8;
    }

}