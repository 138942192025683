.InputBox {
    position: relative;
    background-color: var(--background-color);
    float: left;
    width: 100%;
    margin: 0;
    padding: 8px 4px 0 4px;
    transition: all 0.5s;
    animation: unfold 0.5s linear;
}

.InputForm {
    float: left;
    width: 100%;
    padding: 0;
}

.InputLabel,
.InputField,
.PasswordField,
.InputMessage {
    float: left;
    width: calc(100% - 16px);
    text-align: left;
}

.InputLabel {
    margin: 4px 8px 0 8px;
    padding: 4px 8px 0 8px;
    color: var(--text-color);
    background-color: var(--background-color);
    z-index: 2;
}

.InputField,
.PasswordField {
    margin: 0 8px 0 8px;
    color: var(--text-color);
    background-color: var(--background-color);
    border: 1px solid transparent;
    border-bottom: 1px solid var(--text-color);
    max-height: 250px;
    min-height: 30px;
    user-select: unset;
    z-index: 5;
}


.InputField {
    padding: 8px 8px 2px 8px;
}

.PasswordField {
    padding: 8px 40px 2px 8px;
}

.InputField.Numeric {
    text-align: left;
}

.InputField.Numeric:active,
.InputField.Numeric:focus {
    text-align: right;
    transition: all 0.3s linear;
}

.InputField:hover,
.PasswordField:hover {
    border-bottom: 1px solid var(--primary);
}

.InputField:active,
.PasswordField:active,
.InputField:focus,
.PasswordField:focus {
    border: 1px solid var(--primary);
}

.InputField.StreetNo {
    margin: 0 8px 2px 8px;
}

.InputField.Locality {
    margin: 2px 8px;
}

.InputField.City,
.InputField.State,
.InputField.Country,
.InputField.ZipCode {
    margin: 2px 8px;
    width: calc(50% - 16px);
    z-index: 5;
}

.InputMessage {
    padding: 0 8px 12px 8px;
    z-index: 2;
}

.ActionButton {
    position: absolute;
    right: 8px;
    bottom: 0;
    background-color: transparent;
    color: var(--text-color);
    cursor: pointer;
    padding: 4px 8px;
    z-index: 10;
}

.ActionButton:hover {
    color: var(--primary);
}

.Suggestions {
    position: sticky;
    max-height: 0;
    top: 100%;
    float: left;
    margin: 0 8px;
    width: calc(100% - 16px);
    text-align: left;
    border: 0;
    list-style: none;
    z-index: 200;
    overflow-x: hidden;
    overflow-y: auto;
}

.Suggestions li {
    float: left;
    width: 100%;
    padding: 4px 8px;
    background-color: var(--background-color);
    color: var(--text-color);
    cursor: pointer;
}

.Suggestions li:hover {
    background-color: var(--primary);
    color: var(--background-color);
}

.InputField:focus+.Suggestions,
.Suggestions:hover {
    max-height: 500px;
    border: 1px solid var(--primary);
    border-top: 0;
    animation: unfold 0.5s linear;
    overflow-x: hidden;
    overflow-y: auto;
}


@keyframes unfold {
    0% {
        max-height: 0;
    }

    100% {
        max-height: 500px;
    }
}