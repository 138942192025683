.DeviceContainer {
	position: relative;
	float: left;
	width: 100%;
	height: 100%;
	min-height: 600px;
	margin: 20px 0;
	transition: any 0.5s;
	overflow: hidden;
	/* transform: scale(0.6); */
}

.DeviceContainer .Children {
	position: absolute;
	bottom: 175px;
	left: 0;
	width: 100%;
	display: flex;
	align-items: center;
	flex-flow: column nowrap;
}

.DeviceContainer .Device {
	position: absolute;
	background: #000;
	transition: any 0.5s;
	-webkit-transform-origin: 0 0;
	transform-origin: 0 0;
	-webkit-transform: scale(0.6) translate(-50%, -50%);
	transform: scale(0.6) translate(-50%, -50%);
	left: 50%;
	top: 30%;
	box-shadow: var(--box-shadow);
	z-index: 20;
}

.Device:before {
	content: '';
	display: block;
	position: absolute;
	z-index: 1;
}

.Device:after {
	content: '';
	display: block;
	position: absolute;
	margin: 0;
}

.Device .content {
	background-color: #ffffff;
	overflow: hidden;
	border: none;
	z-index: 2;
}

.Device .content .cover {
	width: calc(100% - 20px);
	height: 150px;
	float: left;
	background: var(--primary);
	padding: 0;
	margin: 25px 10px 16px 10px;
	transition: 1s;
}

.Device .content .cover .logo {
	width: calc(100% - 30px);
	height: 120px;
	margin: 15px;
	background-repeat: no-repeat;
	background-size: contain;
	color: #fff;
	text-shadow: var(--text-shadow);
	transition: 1s;
}

.Device .content .console {
	float: left;
	background: var(--primary);
	margin: 16px 10px;
	transition: 1s;
}

/* The laptop with borders */
.LargeDevice {
	width: 500px;
	height: 300px;
	border-radius: 5px;
	border-style: solid;
	border-color: black;
	border-width: 24px 24px 80px;
}

/* The keyboard of the laptop */
.LargeDevice:after {
	top: calc(50% + 200px);
	left: 50%;
	width: 600px;
	height: 30px;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	background: black;
	border-radius: 5px;
	box-shadow: var(--box-shadow);
}

/* The top of the keyboard */
.LargeDevice:before {
	width: 200px;
	height: 12px;
	bottom: -99px;
	left: 50%;
	-webkit-transform: translate(-50%);
	transform: translate(-50%);
	background: #f1f1f1;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
}

/* The screen (or content) of the device */
.LargeDevice .content {
	width: 450px;
	height: 250px;
}

.LargeDevice .content .console {
	width: 130px;
	height: 80px;
}

.LargeDevice .content .cover .logo {
	padding: 0 0 0 30%;
	text-align: center;
}

.LargeDevice .content .cover .logo h2 {
	font-size: 56px;
	margin: 0 0 -10px 0;
}

.LargeDevice .content .cover .logo h3 {
	margin: -10px 0 0 0;
	font-size: 24px;
}

/* The laptop with borders ends */
/* The device with borders */
.SmallDevice {
	width: 320px;
	height: 600px;
	margin: auto;
	border: 16px black solid;
	border-radius: 36px;
	z-index: 3;
}

/* The horizontal line on the top of the device */
.SmallDevice:before {
	width: 100px;
	height: 5px;
	top: -5px;
	left: 50%;
	transform: translate(-50%);
	background: #333;
	border: 12px solid black;
	border-radius: 0 0 10px 10px;
}

/* The circle on the bottom of the device */
.SmallDevice:after {
	width: 100px;
	height: 10px;
	left: 50%;
	bottom: 10px;
	transform: translate(-50%, -50%);
	background: #333;
	border-radius: 5px;
}

/* The screen (or content) of the device */
.SmallDevice .content {
	width: 288px;
	height: 568px;
	border-radius: 16px;
}

.SmallDevice .content .console {
	width: calc(100% - 20px);
	height: 80px;
}

.SmallDevice .content .cover .logo {
	padding: 0 0 0 50%;
	text-align: left;
}

.SmallDevice .content .cover .logo h2 {
	font-size: 28px;
	margin: 0;
}

.SmallDevice .content .cover .logo h3 {
	margin: 0;
	font-size: 18px;
}

/* The device with borders ends */

/* The device with borders */
.MediumDevice {
	width: 400px;
	height: 600px;
	border: 16px black solid;
	border-top-width: 60px;
	border-bottom-width: 60px;
	border-radius: 36px;
}

/* The horizontal line on the top of the device */
.MediumDevice:before {
	width: 60px;
	height: 5px;
	top: -30px;
	left: 50%;
	transform: translate(-50%, -50%);
	background: #333;
	border-radius: 10px;
}

/* The circle on the bottom of the device */
.MediumDevice:after {
	width: 35px;
	height: 35px;
	left: 50%;
	bottom: -65px;
	transform: translate(-50%, -50%);
	background: #333;
	border-radius: 50%;
}

/* The screen (or content) of the device */
.MediumDevice .content {
	width: 368px;
	height: 485px;
	margin: -1px;
}

.MediumDevice .content .console {
	width: 164px;
	height: 80px;
}

.MediumDevice .content .cover .logo {
	padding: 0 0 0 30%;
	text-align: center;
}

.MediumDevice .content .cover .logo h2 {
	font-size: 42px;
	margin: 0 0 -10px 0;
}

.MediumDevice .content .cover .logo h3 {
	margin: -10px 0 0 0;
	font-size: 18px
}

@media only screen and (min-width: 600px) {
	.Device {
		left: 100%;
		top: 50%;
	}

	@media (max-height: 400px) {
		.Device {
			-webkit-transform: scale(0.5) translate(-115%, -50%);
			transform: scale(0.5) translate(-115%, -50%);
		}
	}

	@media (min-height: 400px) {
		.Device {
			-webkit-transform: scale(0.75) translate(-115%, -50%);
			transform: scale(0.75) translate(-115%, -50%);
		}
	}
}

@media only screen and (min-width: 800px) {
	.Device {
		left: 100%;
		top: 50%;
	}

	@media (max-height: 600px) {
		.Device {
			-webkit-transform: scale(0.6) translate(-115%, -50%);
			transform: scale(0.6) translate(-115%, -50%);
		}
	}

	@media (min-height:600px) {
		.Device {
			-webkit-transform: scale(0.75) translate(-115%, -50%);
			transform: scale(0.75) translate(-115%, -50%);
		}
	}
}

@media only screen and (min-width: 1200px) {
	.Device {
		position: absolute;
		-webkit-transform: scale(1) translate(-115%, -50%);
		transform: scale(1)translate(-115%, -50%);
		left: 100%;
		top: 50%;
		box-shadow: var(--box-shadow);
	}

}