.Modal {
	position: fixed;
	display: block;
	z-index: 500;
	background-color: var(--background-color);
	width: 100%;
	border: 0;
	border-radius: 4px 4px 0 0;
	box-shadow: var(--box-shadow);
	padding: 0 16px 16px 16px;
	left: 0;
	bottom: 0;
	min-height: 50%;
	max-height: 90%;
	animation: popup 0.3s linear;
	overflow-x: hidden;
	overflow-y: auto;
}

.Heading {
	float: left;
	width: 100%;
	position: sticky;
	top: 0;
	background-color: var(--background-color);
	z-index: 5;
}

@keyframes popup {
	from {
		bottom: -50%;
		opacity: 0
	}

	to {
		bottom: 0;
		opacity: 1
	}
}

@media (min-width: 600px) {
	.Modal {
		width: 500px;
		bottom: 50%;
		left: 50%;
		transform: translate(-50%, 50%);
		border-radius: 4px;
	}

	@keyframes popup {
		from {
			bottom: -50%;
			opacity: 0
		}

		to {
			bottom: 50%;
			opacity: 1
		}
	}
}